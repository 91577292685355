const { default: axios } = require("axios");

class OpenAIService {
    constructor() {
        this.apiKey = process.env.OPENAI_API_KEY || "";
        this.baseURL = process.env.OPEN_API_BASE_URL || ""; // OpenAI's endpoint for ChatGPT
    }

    async generateText({ prompt, model = "gpt-4", maxTokens = 100, temperature = 0.7 }) {
        try {
            const response = await axios.post(
               "https://api.openai.com/v1/chat/completions",
                {
                    model: model,
                    messages: [{ role: "user", content: prompt }],
                    max_tokens: maxTokens,
                    temperature: temperature,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer sk-proj-mktWXdRmJGJuBzQTRTwdT3BlbkFJXacnPIHkW4ZNaWDhKCZi`,
                    },
                }
            );

            const choices = response.data.choices;
            console.log("Chargpt reposne::", choices[0].message.content);
            if (choices && choices.length > 0) {
                return { text: choices[0].message.content };
            } else {
                throw new Error("No valid response received from ChatGPT.");
            }
        } catch (error) {
            console.error("ChatGPT API Error:", error.response?.data || error.message);
            throw new Error("Failed to generate text from ChatGPT API.");
        }
    }

    async generateMainAdText({ postData }) {
        const sample = `⚡AED 2,644 per month with 0% down-payment through bank finance

▔▔▔▔▔▔▔▔▔▔
✏️ Key Details:

✳️ Warranty: Until 29-May-2027 or 150,000 Kms
⚙️ Service Contract: 
✂️ Wheel Size: 20"

▔▔▔▔▔▔▔▔▔▔▔▔▔
✅ Why Choose This Car?

Almost New 2024 GAC GS8 4WD - Black

Dive into the future with this sleek and virtually untouched 2024 GAC GS8 4WD.

With only 2,272km on its odometer, it's closer to the idea of BRAND NEW!

The majestic black color amplifies its elegance and high style quotient. Experience a perfect balance of power, comfort, and minimal travel wear in this stunning vehicle. Uncover the true meaning of a luxurious drive today!

▔▔▔▔▔▔▔▔▔▔
Ⓜ️ Website:
www.albacars.ae

⛺ Location:
https://goo.gl/maps/XrGnafTmhQQuRqMV9

⏰ Timing:
7 Days (10:00 AM - 10:00 PM)

▔▔▔▔▔▔▔▔▔▔
⚡ Cash Buyers:
Provide:
1️⃣ Emirates ID
2️⃣ Driving License

▔▔▔▔▔▔▔▔▔▔
✏️ Finance Buyers:

Required Documents:

Employed:
1️⃣ Salary Certificate
2️⃣ 3-month bank statement (stamped)
3️⃣ Passport & Visa copies
4️⃣ Emirates ID copy
(If you've received only one/no salaries and work for a listed company, contact us.)

Self-Employed:
1️⃣ Trade License
2️⃣ MOA
3️⃣ Passport copies of all partners
4️⃣ Emirates ID & Visa copies
5️⃣ 3-month personal bank statement
6️⃣ 3-month company bank statement

Companies:
1️⃣ Trade License
2️⃣ MOA
3️⃣ Passport copies of all partners
4️⃣ 3-month company bank statement

▔▔▔▔▔▔▔▔▔▔
✔️ Car Reservation Options:

To start, we require a deposit of AED 5,000 via:
1️⃣ Credit/Debit Card: Refunded in cash after registration
2️⃣ Cash: Refunded in cash after registration
3️⃣ Cheque: Not cashed, returned after registration

(Terms & Conditions will be shared at booking.)

▔▔▔▔▔▔▔▔▔▔
✉️ Sell Your Car:

Fill out the form here:
https://albacars.ae/sell-used-car-dubai/

We offer cash payments and handle bank early settlements.

▔▔▔▔▔▔▔▔▔▔
⭐ Ref: 7506AC`;

  const prompt = `You are an expert car advertisement copywriter. Create a detailed car advertisement following the exact structure and style of this sample ad, but for the new car details I provide. Keep all sections, formatting, emojis, and dividers exactly the same.

Sample ad format:
${sample}

Generate a new ad for this car with these specific details:
${JSON.stringify(postData)}

Important instructions:
1. Maintain the exact same structure and sections as the sample
2. Keep all emojis, bullet points, and divider lines (▔▔▔▔▔▔▔▔▔▔)
3. Keep all the standard information like website, location, timing, and document requirements exactly the same
4. Only change the car-specific information like model, price, warranty, and features
5. Match the tone and style of the sample description
6. Keep the formatting for finance options, cash buyers, and reservation options identical
7. End with a reference number in the same format`;

    const chatGPTResponse = await this.generateText({ 
        prompt,
        maxTokens: 1500, // Increased for fuller response
        temperature: 0.7
    });
    
    if (!chatGPTResponse || !chatGPTResponse.text) {
        throw new Error("Failed to generate ad text");
    }

    return chatGPTResponse.text.trim();

    }


    async generateIGAdText({ postData }) {
        const sample = `🚘 Mercedes-Benz E53 AMG Coupe | 2023

💰AED 359,999 | AED 7,050 Per Month | 0% Downpayment 

📍 Specification: GCC
📏 Odometer: 20,000 km
🛡 Warranty: Until 05/06/2028 or 105,000 km

📞 Call / WhatsApp:
📲 +971 4 377 2502

🏢 Visit Our Showroom:
🕙 Open daily: 10:00 AM - 10:00 PM

📜 What makes this special:

Experience unparalleled luxury and performance with this stunning 2023 Mercedes-Benz E53 AMG Coupe. Dressed in an elegant white finish, this masterpiece seamlessly combines breathtaking design with cutting-edge technology. With a powerful AMG engine under the hood, it delivers the perfect harmony of speed and refinement.

Meticulously maintained and driven just 20,578 km, this E-Class Coupe feels as sharp and responsive as the day it first hit the road. The premium interior is packed with advanced features, ensuring an effortless and enjoyable driving experience every time.

Don’t miss your chance to own this high-performance marvel. Whether you’re cruising in style or seeking thrill on the open road, the E53 AMG Coupe makes every journey unforgettable. 🚀

✅ Price is inclusive of VAT

🔗 #MERCEDESBENZ #LuxuryCarsDubai #AlbaCars #UsedCarsForSale #CarLifestyle

📌 REF: 8895AC`;

  const prompt = `You are an expert car advertisement copywriter. Create a detailed car advertisement following the exact structure and style of this sample ad, but for the new car details I provide. Keep all sections, formatting, emojis, and dividers exactly the same.

Sample ad format:
${sample}

Generate a new ad for this car with these specific details:
${JSON.stringify(postData)}

Important instructions:
1. Maintain the exact same structure and sections as the sample
2. Keep all emojis, bullet points, and divider lines (▔▔▔▔▔▔▔▔▔▔)
3. Keep all the standard information like website, location, timing, and document requirements exactly the same
4. Only change the car-specific information like model, price, warranty, and features
5. Match the tone and style of the sample description
6. Keep the formatting for finance options, cash buyers, and reservation options identical
7. End with a reference number in the same format`;

    const chatGPTResponse = await this.generateText({ 
        prompt,
        maxTokens: 1500, // Increased for fuller response
        temperature: 0.7
    });
    
    if (!chatGPTResponse || !chatGPTResponse.text) {
        throw new Error("Failed to generate ad text");
    }

    return chatGPTResponse.text.trim();
    }
}

export default OpenAIService;